import React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import AOS from '../../node_modules/aos/dist/aos';
import ControlledCarousel from '../components/Carousel';
import Projects from '../components/Projects';
import Logo from '../assets/images/paintings/logoLpapng.png'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import {
    BrowserRouter as Router,
    
    Link
  } from "react-router-dom";

function HomePage(){
    return(
        <div>
            <div className='main' data-aos="fade-up">
            <Container fluid={true}>
                <Row>
                     <Col xs={12} md={6} className='p-0 pr-5 pb-3' data-aos='fade-right'>
                     
                     <Image src={Logo} fluid></Image>
                         </Col> 

                    <Col xs={12} md={6} className='p-0s' data-aos='fade-up'>
                      
                    <h1 style={{color:'#1f1f1f'}}> Quality is a priority. </h1> 
                <p> We deliver the best of Indian spices and other food products. </p>
                <ButtonToolbar fluid={true} ml-2>
                           <Button variant="outline-success" ><Link className='link' to='/contact'>Contact us</Link></Button>
                  
                   
                </ButtonToolbar>
                    

                  
                        
                    </Col>
                </Row></Container>
            
                {/* <p>Hi There ,</p> */}
                
            </div>
            
            <div className='portfolio' fluid={true}><Projects /></div>
            <h1 style={{textAlign:'center'}}>OUR PRODUCTS</h1>
            <ControlledCarousel />
           
        </div>
    );
}
export default HomePage;
AOS.init();
