import React from 'react';
import seven from '../assets/images/paintings/seven.jpg';
import CardGroup from 'react-bootstrap/CardGroup';
import Card from 'react-bootstrap/Card';
import { ListGroup } from 'react-bootstrap';
import AOS from 'aos/dist/aos';

class OtherAgro extends React.Component {
    
    
    render(){

        

        return(
            
            <ListGroup>
                <ListGroup.Item><CardGroup>
            

            <Card>
              <Card.Img variant="top" src={seven} fluid/>
              
            </Card>
            <Card  > 
                      <Card.Body>
          
              
            </Card.Body>  
                          
                       </Card>
            </CardGroup></ListGroup.Item>
            <ListGroup.Item > Jaggery</ListGroup.Item>
            <ListGroup.Item>Vegetables- Onions</ListGroup.Item>
             <ListGroup.Item>Cereals</ListGroup.Item>
             <ListGroup.Item>Dehydrated Products</ListGroup.Item>
         <ListGroup.Item>Oilseeds</ListGroup.Item>
        </ListGroup>
        )
    }
}
export default OtherAgro;
AOS.init();