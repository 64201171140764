import React from 'react';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Accordion from 'react-bootstrap/Accordion'
import { ListGroup } from 'react-bootstrap';
import AOS from 'aos/dist/aos';
import one from '../assets/images/paintings/one.jpg'
class Spices extends React.Component {
    
    
    render(){

        

        return(
<Accordion defaultActiveKey="0">
<CardGroup>
            

            <Card>
              <Card.Img variant="top" src={one} fluid/>
              
            </Card>
            <Card  > 
                      <Card.Body>
          
              
            </Card.Body>  
                          
                       </Card>
            </CardGroup>
<br></br>


<Card>
    <Accordion.Toggle as={Card.Header} eventKey="0">
      
      
      Chilli - Red and Green
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0">
      <Card.Body>
        
      <ListGroup.Item >Ground</ListGroup.Item>
      <ListGroup.Item >Dry - Whole</ListGroup.Item>
      <ListGroup.Item >Chilli Flakes</ListGroup.Item>
     



      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="1">
      Cumin Seeds
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="1">
      <Card.Body>
      <ListGroup.Item>Powder</ListGroup.Item>
      <ListGroup.Item >Dry - Whole</ListGroup.Item>
      
      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="2">
      Ginger
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="2">
      <Card.Body>
        
      <ListGroup.Item >Ground</ListGroup.Item>
      <ListGroup.Item >Whole</ListGroup.Item>
      
      </Card.Body>
    </Accordion.Collapse>
  </Card>
  
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="3">
      Fennel Seeds
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="3">
      <Card.Body>
        
      
      <ListGroup.Item >Whole</ListGroup.Item>
      
      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="4">
      Turmeric
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="4">
      <Card.Body>
      <ListGroup.Item>Turmeric Finger </ListGroup.Item>
             
              <ListGroup.Item>Powder</ListGroup.Item>
             
      
      </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="5">
      Coriander Seeds
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="5">
      <Card.Body>
      <ListGroup.Item> Whole </ListGroup.Item>
             
              <ListGroup.Item>Powder</ListGroup.Item>
              
      </Card.Body>
    </Accordion.Collapse>
  </Card>

  

   
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="6">
      Other Spices
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="6">
      <Card.Body>
      <ListGroup.Item>Fenugreek</ListGroup.Item>
          
          <ListGroup.Item>Carom Seeds</ListGroup.Item>
          <ListGroup.Item>Garlic</ListGroup.Item>
      </Card.Body>
    </Accordion.Collapse>
  </Card>   



</Accordion>
    


        )
    }
}
export default Spices;
AOS.init();
