import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import AOS from '../../node_modules/aos/dist/aos';

function ContactPage(){
    
    return(
        
        <div className='main' data-aos="fade-up">
            <h1>Get a quote</h1>
            <p>We reply fast. Let us know if you have any questions or feedback too</p>

            <Form action='https://formspree.io/f/xleazrgk' method="POST">

                <Form.Group controlId="formBasicName">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control type="text" name='name' placeholder="John" required/>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" name="_replyto" placeholder="abcd@gmail.com" required/>
                </Form.Group>

                
                <Form.Group controlId="formBasicText">
                    <Form.Label>Message</Form.Label>
                    <Form.Control input as="textarea" name="message" placeholder="Details" required />
                </Form.Group>
               
                <Form.Group controlId="formBasicNumber">
                    <Form.Label>Mobile number with country code</Form.Label>
                    <Form.Control type="text" name='Number' placeholder="Enter phone number" />
                </Form.Group>
                
                <Button variant="outline-success" type="submit">
                    Send
                </Button> 
            </Form>
            <br/>
            <br/>
            <h1>Get in touch</h1>
            <p style={{marginLeft:6}}>
                
            Call/Text Us : +1 8583361044<br/>
            Get a quote : info@lpaexports.com<br/>
            Company Address : Sector 3, K.B.H.B. , Jodhpur , Rajasthan, India. <br/>
            Other Address : Los Angeles , California, USA.<br/>

            </p>



          
            
           </div>




    );
}
export default ContactPage;
AOS.init();
