import React from 'react';
import CardDeck from 'react-bootstrap/CardDeck';
import Card from 'react-bootstrap/Card';

import AOS from 'aos/dist/aos';
import {
    BrowserRouter as Router,
    
    Link
  } from "react-router-dom";
class Projects extends React.Component {
    
    // constructor(props){
    //     super(props);
    //     this.state = {
    //         projects: [
    //             {
    //                 title: "Spices",
    //                 description: "Indian spices are known for their texture , taste and aroma , we deliver the best of them.",
                  
                   
    //             },
    //             {
    //                 title: "Rice",
    //                 description: "Our great indian basmati rice is globally famous, please look for the vareity of rices we deliver",
  
                    
    //             },
               
    //             {
    //                 title: "Fruits",
    //                 description: "Delivering exotic fruits around the globe",
                    
    //             },

    //              {
    //                 title: "Other Agro products",
    //                 description: "We deliver vegetables, pulses ,oilseeds , cereals, jaggery etc. ",
    //                 link: "/products",
                    
    //             }
    //         ]
    //     }
    // }

    render(){

        // let projectList = this.state.projects.map(project => {
        //     return (
        //         <Cards project={project}/>
        //     )
        // })

        return(
            
             <CardDeck>
                <Card href='/products' target='_self' className="text-left bg shadow" data-aos="fade-up" text='white'>
                    <Card.Body>
                        <Card.Title > <Link className='link' to='/products'>Spices</Link></Card.Title>
                        <Card.Text>
                            
                        Indian spices are known for their texture , taste and aroma , we deliver the best of them.
                        </Card.Text>

                        <br></br>
                        <br></br>
                        <br></br>
                    </Card.Body>
                </Card>

                <Card className="text-left bg shadow" data-aos="fade-up" text='white'>
                    <Card.Body>
                    <Card.Title ><Link className='link' to='/products'>Rices</Link></Card.Title>
                        <Card.Text>
                        Our great indian basmati rice is globally famous, please look for the vareity of rices we deliver.
                        
                        </Card.Text>
                        <br></br>
                        <br></br>
                        <br></br>
                    </Card.Body>
                </Card>
                <Card className="text-left bg shadow" data-aos="fade-up" text='white'>
                    <Card.Body>
                    <Card.Title ><Link className='link' to='/products'>Fruits</Link></Card.Title>
                        <Card.Text>
                        Delivering exotic fruits around the globe.
                          </Card.Text>
                          <br></br>
                          <br></br>
                          <br></br>
                    </Card.Body>
                </Card>
                <Card className="text-left bg shadow" data-aos="fade-up" text='white'>
                    <Card.Body>
                    <Card.Title ><Link className='link' to='/products'>Agro Products</Link></Card.Title>
                        <Card.Text>
                        We deliver vegetables, pulses ,oilseeds , cereals, jaggery etc.
                        </Card.Text>
                        <br></br>
                        <br></br>
                        <br></br>
                    </Card.Body>
                </Card>

                {/* {projectList} */}

            </CardDeck>
        )
    }
}
export default Projects;
AOS.init();
