import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { Component } from 'react';


import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app


  // const images = [
  //   spiceBoard,
  //   gst,
  //   iec
    
  // ];
  
  export default class AboutPage extends Component {
        
  
    render() {
    
      
      return (

        


        <div className="main" data-aos="fade-up">




             <h1>About Us</h1>
             <p>We at Lavit Pure Agro, offer to deliver the best quality Indian Spices and Food products across the globe. For us , Quality is the first priority. Lavit Pure Agro is a main exporter of spices, seeds and exotic fruits. As a full administration zest organization; we are committed to supporting our associations with a triumphant system of clients and providers. We keep on growing, in and think its business exercises to mechanical clients of spices. We offer an entire line of entire, re-cleaned, and ground spices, sweet-smelling seeds, and delicious fruits. <br/><br/>

             <h2>Certifications</h2>
             <br></br>
             <ListGroup>
            <ListGroup.Item >APEDA</ListGroup.Item>
            <ListGroup.Item>IEC</ListGroup.Item>
             <ListGroup.Item>GST</ListGroup.Item>
             <ListGroup.Item>CRES - SPICE BOARD</ListGroup.Item>
         
        </ListGroup>
<br></br>
</p>

        {/* <div>
          <button type="button" onClick={() => this.setState({ isOpen: true })}>
            Our Certifications
          </button>
          
  
          {isOpen && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + images.length - 1) % images.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % images.length,
                })
              }
            />
          )} */}
        </div>

        
      );
    }
  }



