import React from 'react';
import five from '../assets/images/paintings/five.jpg';
import CardGroup from 'react-bootstrap/CardGroup';
import Card from 'react-bootstrap/Card';
import { ListGroup } from 'react-bootstrap';
import AOS from 'aos/dist/aos';

class Fruits extends React.Component {
    
    
    render(){

        

        return(
            
            <ListGroup>
            <ListGroup.Item><CardGroup>
            

            <Card>
              <Card.Img variant="top" src={five} fluid/>
              
            </Card>
            <Card  > 
                      <Card.Body>
          
              
            </Card.Body>  
                          
                       </Card>
            </CardGroup></ListGroup.Item>


            <ListGroup.Item >Mango - Alphonso</ListGroup.Item>
            <ListGroup.Item>Pomegranate</ListGroup.Item>
             <ListGroup.Item>Indian Gooseberry</ListGroup.Item>
             <ListGroup.Item>Guava</ListGroup.Item>
         <ListGroup.Item>Papaya</ListGroup.Item>
         <ListGroup.Item>Sweet Lime</ListGroup.Item>
        </ListGroup>
        )
    }
}
export default Fruits;
AOS.init();