import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import AOS from '../../node_modules/aos/dist/aos';
import {
  BrowserRouter as Router,
  
  Link
} from "react-router-dom";
import {Tabs,Tab} from 'react-bootstrap';
import Rice from '../components/Rice';
import Spices from '../components/Spices';
import OtherAgro from '../components/OtherAgro';
import Fruits from '../components/Fruits';
import {useEffect, useState } from "react";
function ProductsPage(){
    return(
        
            <div className='main' data-aos="fade-up">
                {/* <p>Hi There ,</p> */}
                <h1><span>Our Products</span> </h1>
                <p></p>
                <ButtonToolbar fluid={true} ml-2>
                      <Button variant="outline-success" ><Link className='link' to='/contact'>Contact us</Link></Button>
                                      
                </ButtonToolbar>
                <br></br>
<Tabs style={{backgroundColor: '#343434' }} defaultActiveKey="Spices" renderActiveTabContentOnly={true} id="uncontrolled-tab-example" className="mb-3">
  <Tab style={{color: 'Maroon' }} eventKey="Spices" title="Spices">
    <Spices/> 
  </Tab>
  <Tab eventKey="Rice" title="Rice">
    <Rice />
  </Tab>
  <Tab eventKey="Fruits" title="Fruits" >
    <Fruits/>
  </Tab>
  <Tab eventKey="OtherAgro" title="Agro" >
    <OtherAgro/>
  </Tab>
  
</Tabs>

<br></br>
<br></br>

<h3>Need more details ? , get our brochure in your inbox </h3>
        

<Form action='https://formspree.io/f/xleazrgk' method="POST">

<br></br>

<Form.Group controlId="formBasicEmail">
    <Form.Label>Email address</Form.Label>
    <Form.Control type="email" name="_replyto" placeholder="john123@gmail.com" required/>
</Form.Group>


<Button variant="outline-success" type="submit">
    Send
</Button> 
</Form>   
                
                


            </div>

            
     
         
    );
}
export default ProductsPage;
AOS.init();
