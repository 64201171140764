import React from 'react';
import CardGroup from 'react-bootstrap/CardGroup';
import Card from 'react-bootstrap/Card';
import rice from '../assets/images/paintings/rice.jpg';
import { ListGroup } from 'react-bootstrap';
import AOS from 'aos/dist/aos';
class Rice extends React.Component {
    
    
    render(){

        

        return(
            
             <ListGroup>
            <ListGroup.Item><CardGroup>
            

            <Card>
              <Card.Img variant="top" src={rice} fluid/>
              
            </Card>
            <Card  > 
                      <Card.Body>
          
              
            </Card.Body>  
                          
                       </Card>
            </CardGroup></ListGroup.Item>


            <ListGroup.Item >Basmati</ListGroup.Item>
            <ListGroup.Item>Mogra</ListGroup.Item>
             <ListGroup.Item>Parmal</ListGroup.Item>
             <ListGroup.Item>Pusa</ListGroup.Item>
         <ListGroup.Item>Sharbati</ListGroup.Item>
         <ListGroup.Item>Sugandha</ListGroup.Item>
        </ListGroup>
        )
    }
}
export default Rice;
AOS.init();
