import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import one from '../assets/images/paintings/one.jpg';
import two from '../assets/images/paintings/two.jpg';

import four from '../assets/images/paintings/four.jpg';
import five from '../assets/images/paintings/five.jpg';
import { Card } from 'react-bootstrap';


function ControlledCarousel() {
  
    return (
        <div className='main mt-100'>
            <Container fluid={true}>
                <Row>
                    <Col xs={12} md={6} className='p-0 pr-5 pb-3' data-aos='fade-right'>
                         <Card.Text className='mb-100'>We at Lavit Pure Agro, offer to deliver the best quality Indian Spices and Food products across the globe. For us , Quality is the first priority. Lavit Pure Agro is a main exporter of spices, seeds and exotic fruits. As a full administration zest organization; we are committed to supporting our associations with a triumphant system of clients and providers. We keep on growing, in and think its business exercises to mechanical clients of spices. We offer an entire line of entire, re-cleaned, and ground spices, sweet-smelling seeds, and delicious fruits. </Card.Text>
                        {/* <Button className='mb-3' variant="outline-success" href='' target='_blank'>Link</Button> */}
                    
                    
                    </Col>

                    <Col xs={12} md={6} className='p-0' data-aos='fade-up'>
                
                        <Carousel className='w-100'>
                            <Carousel.Item>
                            <img
                                className="w-100"
                                src={one}
                                alt="First slide"
                            />
                            </Carousel.Item>
                           
                            
                            <Carousel.Item>
                            <img
                                className="w-100"
                                src={two}
                                alt="2nd slide"
                            />
                            </Carousel.Item>

                            
                            <Carousel.Item>
                            <img
                                className="w-100"
                                src={four}
                                alt="4th slide"
                            />
                            </Carousel.Item>
                            
                            <Carousel.Item>
                            <img
                                className="w-100"
                                src={five}
                                alt="5th slide"
                            />
                            </Carousel.Item>
                            
                        </Carousel>
                    </Col>
                </Row>
            </Container>
      </div>
    );
}

export default ControlledCarousel;